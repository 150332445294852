import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import CustomTabs from "components/CustomTabs/CustomTabs.jsx"
import Carousel from "react-slick"
import Card from "components/Card/Card.jsx"
import Video from "components/Video/Video.jsx"

import defaultSectionStyle from "assets/jss/finwe/defaultSectionStyle.jsx"

import image1 from "assets/img/cases/faladin/eucnc_demo.jpg"
import image2 from "assets/img/cases/faladin/frontend.jpg"
import image3 from "assets/img/cases/faladin/digitwin.jpg"
import image4 from "assets/img/cases/faladin/avatars2.jpg"
import image5 from "assets/img/cases/faladin/printer.jpg"
import image6 from "assets/img/cases/faladin/virtual_printer.jpg"

import XR6G from "assets/img/logos/6G-XR-480x480.png"
import EU from "assets/img/logos/EU-480x480.png"
import SNS6G from "assets/img/logos/6GSNS-480x480.png"
import UOulu from "assets/img/logos/univoulu-480x480.png"

class PaaliSection extends React.Component {
  render() {
    const { classes } = this.props
    const carouselSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    }
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <div id="SectionContent">
            <div className={classes.title}>
              <h2>
                FabLab Digital Twin (EU 6GSNS 6G-XR Open Call 1 / Faladin)
              </h2>
              <h4>Collaborative Design Reviews for Co-creation Spaces in XR</h4>
              <br />
            </div>

            <CustomTabs
              headerColor="info"
              tabs={[
                {
                  tabName: "Photos",
                  tabIcon: undefined,
                  tabContent: (
                    <div className={classes.section}>
                      <div className={classes.container}>
                        <GridContainer>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            className={classes.marginAuto}
                          >
                            <Card carousel>
                              <Carousel {...carouselSettings}>
                                <div>
                                  <img
                                    src={image1}
                                    alt="Slide"
                                    className="slick-image"
                                  />
                                </div>
                                <div>
                                  <img
                                    src={image2}
                                    alt="Slide"
                                    className="slick-image"
                                  />
                                </div>
                                <div>
                                  <img
                                    src={image3}
                                    alt="Slide"
                                    className="slick-image"
                                  />
                                </div>
                                <div>
                                  <img
                                    src={image4}
                                    alt="Slide"
                                    className="slick-image"
                                  />
                                </div>
                                <div>
                                  <img
                                    src={image5}
                                    alt="Slide"
                                    className="slick-image"
                                  />
                                </div>
                                <div>
                                  <img
                                    src={image6}
                                    alt="Slide"
                                    className="slick-image"
                                  />
                                </div>
                              </Carousel>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      </div>
                    </div>
                  ),
                },
                {
                  tabName: "Video",
                  tabIcon: undefined,
                  tabContent: (
                    <div className={classes.container}>
                      <div class="sketchfab-embed-wrapper">
                        <iframe
                          src="https://player.vimeo.com/video/1030845099?h=5cfebfacdf"
                          width="1024"
                          height="600"
                          frameborder="0"
                          allow="autoplay; fullscreen"
                          allowfullscreen
                        ></iframe>
                      </div>
                    </div>
                  ),
                },
                {
                  tabName: "More Info",
                  tabIcon: undefined,
                  tabContent: (
                    <GridContainer>
                      <div className={classes.typo}>
                        <p>
                          FALADIN project innovated in the field of co-creation process by 
                          developing a digital twin platform for FabLabs. The result is an 
                          online web service that facilitates seamless collaboration between 
                          users and FabLab operators, streamlining 3D model review and 3D 
                          printing process.
                        </p>
                        <p>
                          Leveraging both traditional web technologies as well as modern 
                          immersive WebXR, the platform enables users to log in and upload 
                          their 3D models via web browser, schedule an online review session, 
                          and then use an extended reality (XR) headset to participate in the 
                          review meeting within an immersive 3D representation of the 
                          co-creation space. We used Meta Quest 3 for VR style immersive
                          experience and Apple Vision Pro for AR style immersive experience.
                        </p>
                        <p>
                          The environment facilitates 3D model examination and live adjustments 
                          in co-operation with FabLab operators using avatars, voice communication, 
                          and real-time hand tracking. In addition, video streams, such as a 
                          screenshare from a CAD tool or 3D printer's slicer tool, can be viewed 
                          from a large virtual display within the environment. The solution also
                          combines virtual and physical worlds. For example, by placing a virtual
                          3D model on the print bed of a 3D printer’s digital twin and pushing a 
                          virtual print button, user can start real 3D printing process on a 
                          connected physical 3D printer and even observe live video feeds from 
                          multiple cameras attached to the 3D printer, within the digital twin 
                          environment. This is an example of merging XR and IoT technologies.
                        </p>
                        <p>
                          By integrating cutting-edge technology into the co-creation process 
                          using open and freely available technologies, the project created a 
                          dynamic and inclusive environment for innovation and creativity.
                          The concept can be easily modified from FabLabs to other co-creation
                          needs, such as industrial design and quality control. Moreover,
                          the platform can be used for remote collaboration, enabling global
                          co-creation. Finally, the 3D models could also be scanned from physical
                          objects using photogrammetry, a 3D scanner, a 3D point cloud camera,
                          or a LIDAR.
                        </p>
                        <p>
                          The work was integrated with other 6G-XR projects, 6G-SLICE by Allbesmart 
                          (Sliced O-RAN) and BANQ by Kaitotek (QoS Monitoring). It was first 
                          demonstrated in EuCNC 2024 conference in Antwerp, Belgium.
                          The project was funded by the European Union and the 6G-XR project.
                          The demo video was produced by the University of Oulu.
                        </p>
                        <p>
                          <b>
                            WebXR based AR/VR solutions work on all modern XR headsets, such as 
                            Meta Quest 2 and 3, and Apple Vision Pro, without installing an app.
                            They also work on PCs, mobile phones and tablets inside a web browser.
                            Contact us to learn more about customized WebXR solutions.
                          </b>
                        </p>
                      </div>
                      <div className={classes.typo}>
                        <GridContainer>
                          <GridItem
                            xs={4}
                            sm={3}
                            md={2}
                            className={
                              (classes.marginAuto, classes.beforeTitle)
                            }
                          >
                            <h4>Customer:</h4>
                          </GridItem>
                          <GridItem
                            xs={4}
                            sm={3}
                            md={2}
                            className={classes.marginAuto}
                          >
                            <img
                              src={XR6G}
                              alt="6G-XR"
                              className={
                                classes.imgRounded + " " + classes.imgFluid
                              }
                            />
                          </GridItem>
                          <GridItem
                            xs={4}
                            sm={3}
                            md={2}
                            className={classes.marginAuto}
                          >
                            <img
                              src={EU}
                              alt="Co-funded by the European Union"
                              className={
                                classes.imgRounded + " " + classes.imgFluid
                              }
                            />
                          </GridItem>
                          <GridItem
                            xs={4}
                            sm={3}
                            md={2}
                            className={classes.marginAuto}
                          >
                            <img
                              src={SNS6G}
                              alt="6GSNS"
                              className={
                                classes.imgRounded + " " + classes.imgFluid
                              }
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem
                            xs={4}
                            sm={3}
                            md={2}
                            className={
                              (classes.marginAuto, classes.beforeTitle)
                            }
                          >
                            <h4>In co-operation:</h4>
                          </GridItem>
                          <GridItem
                            xs={4}
                            sm={3}
                            md={2}
                            className={classes.marginAuto}
                          >
                            <img
                              src={UOulu}
                              alt="University of Oulu"
                              className={
                                classes.imgRounded + " " + classes.imgFluid
                              }
                            />
                          </GridItem>
                        </GridContainer>
                      </div>
                    </GridContainer>
                  ),
                },
                {
                  tabName: "Links",
                  tabIcon: undefined,
                  tabContent: (
                    <div>
                      <p className={classes.textCenter}>
                        <a href="https://6g-xr.eu/">6G-XR Project Site</a>
                      </p>
                      <p className={classes.textCenter}>
                        <a href="https://6g-xr.eu/open-calls/oc1-results/">
                          6G-XR Open Call 1 Results (find FALADIN)
                        </a>
                      </p>
                      <p className={classes.textCenter}>
                        <a href="https://www.youtube.com/watch?v=WKhJtyhehMI">
                          6G XR OC1 Demo Overview: Faladin (Youtube)
                        </a>
                      </p>
                      <p className={classes.textCenter}>
                        <a href="https://6g-xr.eu/2024/06/17/eucnc-6g-summit-2024/">
                          6G XR EuCNC & 6G Summit 2024: Highlights
                        </a>
                      </p>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(defaultSectionStyle)(PaaliSection)
